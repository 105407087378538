import {useStripe, useElements, PaymentElement, CardElement} from '@stripe/react-stripe-js';
import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, CircularProgress, useMediaQuery, Typography } from "@mui/material";
import pay_load from "../assets/icons/Frame 217.svg"
import "../styles/checkout.css"
import { CheckBox } from '@mui/icons-material';

const CheckoutForm = (props) => {
const navigate = useNavigate()
const [uid, setUID] = useState(props.uid)
const save_url = props.saved_url
const username = props.user_name
const cancel = props.cancel
const [result, setResult]=useState('')
const [payment_loading, setPaymentLoading] = useState(false)
const [loading, setLoading] = useState(false);
const [paymentFailed, setPaymentFailed] = useState(false);
const [pay, setPay]=useState('')
const mob =  useMediaQuery('(max-width: 600px)');
const [paysuccess, setPaymentsucess] = useState('')
  const stripe = useStripe();
  const elements = useElements();
  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#424770',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
  const user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))

  function handleCancel(){
    // console.log('Cancel button clicked');
    // console.log(uid)
    const url = `https://accounts.arivu-iq.com/payment/?uid=${uid}`;
    //const url = `http://localhost:3000/payment/?uid=${uid}`;
    window.location.href = url;
    //navigate(`/payment/?uid=${uid}`)      
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submissvar(--pallate-base-white, #FFF)ion until Stripe.js has loaded.
      return;
    }
    setPaymentLoading(true)

    
    
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      redirect:"if_required"
      // confirmParams: {
        //const url = `https://dnavizstage.nlugpt.io//payment/?username=${username}`;
        //window.location.href = `http://localhost:3000/chat/`,
        //return_url: `http://localhost:3000/chat/?paysucess=true`,
        // return_url:`https://dnaviz.nlugpt.io/chat/?paysucess=true`,
        //return_url: `https://localhost:3001/payment/?username=${username}&paysucess=true`,
      // },
    });
    setResult(result)
    
    if (result.error) {
      setPaymentLoading(false)
      setPaymentFailed(true)
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      setPaymentsucess(true)
      setPaymentFailed(false)
      const {data}=await axios.post(save_url+'dnaviz/payment_success/',{
      "username":username,
      })
      window.location.href=`https://dnaviz.aipeople.arivu-iq.com/chat/?paysuccess=true`
    }
  };

  return (
    <>
      <Box sx={{ display: payment_loading === true ? "none" : "block" }}>
        <Box className="pay-in-main">
          <Box className="paychoice">
            <Box className="paychoicehead">
              <Typography sx={{ alignSelf: "stretch", color: "#FFF", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "800", lineHeight: "32px" }}>Right  Choice!</Typography>
              <Typography sx={{ alignSelf: "stretch", color: "#FFF", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px", gap: "10px" }}>You've just selected
                <span style={{ color: "var(--pallate-pink-500, #EC4899)", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" }}>
                  {" "}Premium{" "}
                </span>
                Plan tailored to meet all your needs. Enjoy the unmatched benefits!
              </Typography>
            </Box>
            <Box className="pay-in-line"></Box>
          </Box>
          <Box className="card-details">
            <Typography sx={{ alignSelf: "stretch", color: "#FFF", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "800", lineHeight: "32px" }}>Enter you card Details</Typography>
            <Box className="checkout">

              <form onSubmit={handleSubmit} style={{ width: "100%", display: "flex", flexDirection: "column", gap: "24px", height: mob ? "100vh" : "", overflowY: mob ? "auto" : "" }}>
                <PaymentElement options={cardElementOptions} />
                <Box className="save-card">
                </Box>
                <Box className="checkout-buttons">
                  <button disabled={!stripe} style={{ display: "flex", width: "125px", height: "35px", padding: "8px 12px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "4px", background: "var(--pallate-indigo-500, #6366F1)", cursor: "pointer" ,border:"none"}}>
                    <Typography sx={{ color: "var(--pallate-base-white, #FFF)", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "400", lineHeight: "20px" }}>Submit</Typography>
                  </button>
                  <button style={{ display: "flex", width: "125px", height: "35px", padding: "8px 12px", justifyContent: "center", alignItems: "center", gap: "8px", borderRadius: "4px", border: "1px solid var(--pallate-indigo-500, #6366F1)", background: "none", cursor: "pointer"}} onClick={() => cancel(false)}>
                    <Typography sx={{ color: "var(--pallate-indigo-500, #6366F1)", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "500", lineHeight: "20px" }}>Cancel</Typography>
                  </button>
                </Box>
                {paymentFailed==true&&<Box >
                  <Typography sx={{ color: "#EF4444",textAlign:"center" }}>"Your Card was Declined, Please Provide Valid Card Details"</Typography>
                </Box>}
              </form></Box></Box></Box>
      </Box>
      <Box className="payment_loading" sx={{ display: payment_loading == true ? "flex" : "none"}}>
        
          <Box className="pay_load_in">
            <Box className="pay_load_text">
              <Typography sx={{ alignSelf: "stretch", color: "#FFF", fontFamily: "inter", fontSize: "18px", fontStyle: "normal", fontWeight: "600", lineHeight: "28px" }}>Do not refresh / close or  go back to window during the payment process</Typography>
              <Typography sx={{ alignSelf: "stretch", color: "#FFF", fontFamily: "inter", fontSize: "14px", fontStyle: "normal", fontWeight: "500", lineHeight: "20px" }}>Please wait a minute, Your patience is appreciated as we securely process your payment. Thank you!</Typography>
            </Box></Box>
            <Box>
            <img src={pay_load} style={{ maxWidth: "209px",width:"100%", height: "16px", flexShrink: "1" }} />

            </Box>
          
      </Box>
    </>
  )
};

export default CheckoutForm;