import { Box, CircularProgress, Typography } from "@mui/material";
import "../styles/Login.css"
import pana from "../assets/icons/computer_pana.svg"
import user from "../assets/icons/user.svg"
import lock from "../assets/icons/lock.svg"
import eyeSlash from "../assets/icons/eye-slash.svg"
import eye from "../assets/icons/eye.svg"
import login from "../assets/icons/sign-in.svg"
import frame from "../assets/icons/Frame_mixed .svg"
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactGA from "react-ga4";
import { gtag_MeasurementID } from "../config";
ReactGA.initialize(gtag_MeasurementID);

export function Login(props) {
    const api_base_url = props.api_base_url
    const [showPassword, setShowPassword] = useState(false);
    const [details,setDetails] = useState(
    {
        username:"",
        password:""
    }
    );
    const [error,setError] = useState('')
    const [isLogined,setIsLogined] = useState(false)
    const navigate = useNavigate()

    const handlePasswordHide = (event) =>{
        event.preventDefault()
        setShowPassword(!showPassword)
    }
    const handleSubmit = async(e)=>{
        e.preventDefault()
        let error = {}
        let details_keys = Object.keys(details)
        console.log(Object.values(details).length )
        if(Object.values(details).length > 0){
            details_keys.map((key)=> {
            
             if (!details["username"]){
                error["username"] = "Please enter user name here"
            }
            if(!details["password"]){
                error["password"] = "Please enter password here"

            }})
        }
        console.log(error)
        setError(error)
        console.log(Object.values(error).length)
        if(Object.values(error).length === 0){
            // console.log(details)
            try{
                setIsLogined(true)
                let res = await axios.post(api_base_url+'account/login/',details).catch((error)=>setError("Your username or password is not matching"))
                setIsLogined(false)
                console.log(res)
                if(res.data.token){
                    window.sessionStorage.setItem('userdetails',JSON.stringify({"username":details.username}))
                    ReactGA.event("login",{
                        category: "Login",
                        action: "login",
                        user_name: details.username
                    });
                    navigate('/payment')
                }
                else{
                    error["error"] = "Your username or password is not matching"
                    setError(error)
                }
            }
            catch(error){
                error["error"] = "Your username or password is not matching"
                setError(error)
            }
        }
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handleSubmit(e); 
        }
      };
    useEffect(()=>{
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    },[])
    return (
        <Box className="loginContainer">
            { isLogined ?  
                <Box sx={{textAlign:"center",width:"100%"}}>
                    <CircularProgress size={50}/>
                </Box>
            :
            <>
                <Box className="Rectangleframe">
                    <Typography sx={{ color: "var(--pallate-gray-300, #D1D5DB)", marginBottom: "30px", width: "477px", textAlign: "center", fontFamily: "inter", fontSize: "48px", fontStyle: "normal", fontWeight: "400", lineHeight: "57px" }}>
                        <span style={{ color: "var(--pallate-blue-gray-50, #F8FAFC)", marginRight: "20px", fontFamily: "inter", fontSize: "48px", fontStyle: "normal", fontWeight: "800", lineHeight: "57px" }}>
                            Welcome back!
                        </span>
                        Log in to your account
                    </Typography>
                    <img src={pana} style={{ width: "100%", maxWidth: "604px", height: "375.51px", flexShrink: "1" }} alt="pana" />
                </Box>
                <Box className="container-right">
                    <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100vh" }}>
                        <Box sx={{ width: "100%", display: "flex", alignItems: "flex-start", justifyContent: "flex-end", marginRight: "10px" }}>
                            <img src={frame} style={{ height: "22px", flexShrink: "0", marginTop: "42px", marginRight: "32px" }} alt="frame" />
                        </Box>
                        <Box sx={{ display: "inline-flex", height: "-webkit-fill-available", width: "100%", flexDirection: "column", justifyContent: "center", alignItems: "center", boxShadow: "0px 0px 80px 0px rgba(0, 0, 0, 0.10)" }}>

                            <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                <Box sx={{ display: "flex", width: "100%", maxWidth: "603px", padding: "32px", flexDirection: "column", alignItems: "center", gap:error?"38px":"56px", background: "var(--pallate-blue-gray-700, #334155)" }}>
                                    <Typography sx={{ height: "32px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "center", fontFamily: "inter", fontSize: "30px", fontStyle: "normal", fontWeight: "700", lineHeight: "36px" }}>Login</Typography>
                                    {error.error && <Box sx={{background: "var(--pallate-red-200, #FECACA)", display:"flex", padding:"8px", justifyContent:"center", alignItems:"center", gap:"8px", alignSelf:"stretch"}}>
                                    <Typography sx={{color: "var(--pallate-red-800, #991B1B)", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{error.error}</Typography>
                                    </Box>}
                                    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "48px", alignSelf: "stretch" }}>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-star", gap: "24px", alignSelf: "stretch" }}>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>
                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    User Name 
                                                </Typography>
                                                <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <img src={user} alt="user" />
                                                    <input id="username" name="username" value={details.username} onKeyDown={(e)=>handleKeyPress(e)}  onChange={(e)=>{setDetails({...details, [e.target.name] : e.target.value})}} placeholder="Enter your user name" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} />
                                                </Box>
                                                <Typography sx={{color: "var(--light-text-danger-text, #EF4444);", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{error.username}</Typography>
                                            </Box>
                                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "8px", alignSelf: "stratch", width: "100%" }}>

                                                <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #F8FAFC)", textAlign: "left" }}>
                                                    Password 
                                                </Typography>

                                                <Box sx={{ display: "flex", justifyContent: "space-between", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                                    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", width: "100%" }}> <img src={lock} alt="lock" />
                                                        <input id="password" name="password" type={showPassword?"text":"password"} value={details.password} onKeyDown={(e)=>handleKeyPress(e)}  placeholder="Enter Password" onChange={(e)=>setDetails({...details,[e.target.name]:e.target.value})} style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} /></Box>
                                                <button style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }} onClick={(e) => handlePasswordHide(e)}> {!showPassword?<img src={eyeSlash} style={{ display: "flex" }} alt="eye" />:<img src={eye}  style={{ display: "flex" }}  alt="eye-icon" />}</button>
                                
                                                </Box>
                                                <Typography sx={{color: "var(--light-text-danger-text, #EF4444);", textAlign:"center", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"400", lineHeight:"28px"}}>{error.password}</Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: "16px", alignSelf: "stretch" }}>
                                            <button disabled={details.username && details.password?false:true} onClick={(e)=>handleSubmit(e)} style={{ display: "flex", flexDirection: "row", color: "#FFF", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", 
                                                background: !details.username || !details.password?"#475569" :"#9746FF", padding: "12px", justifyContent: "center", alignItems: "center", alignSelf: "stretch", borderRadius: "4px", border: 'none', cursor: !details.username || !details.password?"not-allowed": "pointer" }}>Login
                                                <img src={login} style={{ display: "flex", marginLeft: "8px", width: "16px", height: "24px", padding: "3.278px 3.25px 3.248px 3.25px", justifyContent: "center", alignItems: "center" }} alt="login" />
                                            </button>
                                            <Box sx={{ display: "flex", padding: "12px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px" }}>
                                                <button onClick={() => { navigate('/forgotpassword') }} style={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", backgroundColor: "transparent", border: "none", cursor: "pointer" }}>Forgot Password?</button>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                <Box sx={{ display: "flex", width: "100%", maxWidth: "603px", padding: "32px", justifyContent: "center", alignItems: "center", gap: "8px", alignSelf: "stretch", background: "var(--pallate-blue-gray-900, #0F172A)" }}>
                                    <Typography sx={{ color: "var(--pallate-blue-gray-200, #E2E8F0)", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px" }}>Don't have an account?</Typography>
                                    <Typography onClick={() => { navigate('/') }} sx={{ color: "#00C2FF", fontFamily: "inter", fontSize: "16px", fontStyle: "notmal", fontWeight: "400", lineHeight: "24px", '&:hover': { cursor: "pointer" } }}>Signup</Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            }
        </Box>
    )

}