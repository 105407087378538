import { Route, Routes } from "react-router-dom";
import './App.css';
import { Signup } from "./pages/Signup";
import { Login } from "./pages/Login"
import { ForgotPassword } from "./pages/forgot-password";
import { ResetPassword } from "./pages/Reset-password";
import { Payment } from "./pages/payment";
import { Payment_Success } from "./pages/payment-sucess.js";

function App() {
  //const api_base_url = 'http://127.0.0.1:8000/'
  const api_base_url = 'https://controllayer-prod.arivu-iq.com/'
  return (
    <div className="App">
      <Routes>
        {/* <Route path="/" element={<Signup api_base_url={api_base_url}/>}></Route>
        <Route path="/login" element={<Login api_base_url={api_base_url}/>}></Route>
        <Route path="/forgotpassword" element={<ForgotPassword api_base_url={api_base_url}/>}></Route>
        <Route path="/reset-password" element={<ResetPassword api_base_url={api_base_url}/>}></Route> */}
        <Route path="/payment" element={<Payment api_base_url={api_base_url}/>}/>
        <Route path="/payment_sucess" element={<Payment_Success api_base_url={api_base_url}/>}/>
      </Routes>
    </div>
  );
}

export default App;
