import { Box, CircularProgress,useMediaQuery, Typography ,Button, Menu, Modal, MenuItem} from "@mui/material";
import Select from 'react-select'
import "../styles/payment.css"
import SetIcon from "../assets/icons/ant-design_setting-twotone.svg"
import UseIcon from "../assets/icons/mdi_report-box-outline.svg"
import InvIcon from "../assets/icons/uil_invoice.svg"
import HelpIcon from "../assets/icons/material-symbols_help.svg"
import CredIcon from "../assets/icons/teenyicons_c-outline.svg"
import PieIcon from "../assets/icons/ant-design_pie-chart-twotone.svg"
import ChartIcon from "../assets/icons/heroicons-outline_save-as.svg"
import QueIcon from "../assets/icons/ph_question-duotone.svg"
import PCredIcon from "../assets/icons/teenyicons_c-outline_pre.svg"
import PPieIcon from "../assets/icons/ant-design_pie-chart-twotone_pre.svg"
import PChartIcon from "../assets/icons/heroicons-outline_save-as_pre.svg"
import PQueIcon from "../assets/icons/ph_question-duotone_pre.svg"
import PTableIcon from "../assets/icons/tabler_topology-complex_pre.svg"
import PExportIcon from "../assets/icons/ph_export-duotone_pre.svg"
import Semoji from "../assets/icons/emojione_party-popper.svg"
import Close from "../assets/icons/close-circle_svgrepo.com.svg"
import Polygon from "../assets/icons/Polygon 1.svg"
import Calendar from 'react-calendar';
import Drop from "../assets/icons/chevron-down.svg"
import arrowLeft from "../assets/icons/arrow-left (1).svg";
import shield from "../assets/icons/Shield Star.svg";
import support from "../assets/icons/support_agent.svg";
import { useState, useEffect,useMemo } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./checkoutForm";
import countryList from 'react-select-country-list'
import { useParams } from "react-router-dom";
import { Bar, Line, Pie, Doughnut, PolarArea, Radar, Scatter, Bubble } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    ArcElement,
    Tooltip,
    Legend,
    BarElement,
    RadialLinearScale

} from 'chart.js';


ChartJS.register(
    BarElement,
    RadialLinearScale,
    ArcElement,
    LineElement,
    PointElement,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend
);

export function Payment(props) {
    const navigate = useNavigate()
    const save_url= props.api_base_url
    //const stripePromise = loadStripe('pk_test_51LJQvQGbxvISa8ejCyLiIAZ19LOyCeufUM2kxWM9aZWaHsYVl8neXDHYPnLG3tqRpydmxxW7WRb5Q0OyKfaGiDBl00wbiU1HMT');
    const stripePromise = loadStripe('pk_live_51LJQvQGbxvISa8ejiN4QqPnZWdFB0yzBIFTUmXtXUU89VWMoT8ptChjTNlCrZkH8FEAeIclnumpWe66UNan5TkoG00bAqmIHII');
    const [data,setData]=useState({})
    const [Optionsopen,setOptionsopen] = useState(false)
    const [value, setValue] = useState('')
    const [anchorEl, setAnchorEl] = useState(null);
    const [stripedata,setStripeData]=useState({})
    const user_details = JSON.parse(window.sessionStorage.getItem('userdetails'))
    const [usageReport, setUsageReport] = useState(false)
    const [billDetails, setBillDetails] = useState(true)
    const [usageColor, setUsageColor] = useState('var(--pallate-blue-gray-800, #1E293B)')
    const [billColor, setBillColor] = useState('rgba(34, 211, 238, 0.20)')
    const [paymentTemplate,setPaymentTemplate]=useState(false)
    const [contactTemplate,setContactTemplate]=useState(false)
    const [date, setDate] = useState("2023-01")
    const [barvalue, setBarValue] = useState({})
    const [tier,setTier]=useState('free plan')
    const country_options = useMemo(() => countryList().getData(), [])
    const [progressWidth, setProgressWidth] = useState('')
    const [actprogressWidth, setActProgressWidth] = useState('')
    const [actprogressmainWidth, setActProgressMainWidth] = useState('')
    const [usageamtWidth, setUsageAmtWidth] = useState('')
    const queryParameters = new URLSearchParams(window.location.search)
    const [paysuccess, setPaysucess] = useState('')
    const [contactsuccess, setContactsucess] = useState('close')
    const [paydate, setPayDate] = useState('')
    const [credamt, setCredAmt] = useState('')
    const [credits, setCredits] = useState('')
    const [contactname, setContactName] = useState('')
    const [contactmail, setContactEmail] = useState('')
    const [contactnumber, setContactNumber] = useState('')
    const [contactmessage, setContactMessage] = useState('')
    const [contactcountry, setContactCountry] = useState('')
    const [viewby, setViewBy] = useState('')
    const [numbererror, setNumberError] = useState('')
    const [nameerror, setNameError] = useState('')
    const [required, setRequired] = useState({})
    const [emailerror, setEmailError] = useState('')
    const [use_width_mult, setUseWidthMult] = useState(5.323)
    const mob =  useMediaQuery('(max-width: 600px)');
    const [username, setUsername] = useState('')
    let unique_id = queryParameters.get('uid')
    // const [uid, setUID] = useState(queryParameters.get('uid')?queryParameters.get('uid'):"")
    //let username = queryParameters.get("username")
    let pay_status = queryParameters.get('paysucess')
    
     

    function usernameValidation(username) {
        var pattern = /^[a-zA-Z0-9-_]+$/;
        return pattern.test(username);
      }
      function emailValidation(username) {
        var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(username);
      }
    // useEffect(()=>{
    //     console.log("we are here")
    //     console.log(uid)
    //     async function getData(){
    //     const {data}= await axios.get(save_url+"dnaviz/payment_uid/"+unique_id)
    //     console.log(data)
    //     setUsername(data.username)
    //     }
        
    //     getData()
    // },[uid])

     useEffect(()=>{
     async function fetchData() {
        
        const URL = window.location.href;
        // window.addEventListener('data', (event) => {
        //     const url = 'http://localhost:3000'
        //     if (event.origin === url) {
        //         const data = event.data;
        //         console.log('Received message from Project A:', data);
        //         setUsername(data.username)
        //       }
        //     });
        // setUsername(localStorage.getItem('sharedValue'))
        // console.log(username)
        
        //setUsername(user_data.username)
        
        const {data}=await axios.post(save_url+'dnaviz/payment_info/',{
            "unique_id":unique_id,
        })
        console.log(data)
        setCredits(data.credits_left)
        setUsername(data.username)
        
        
        
        if (data.user_tier=="paid plan"){
            // const options = {
            //     year: "numeric",
            //     month: "short",
            //     day: "2-digit",
            //     hour: "2-digit",
            //     minute: "2-digit",
            //     hour12: false,
            //   };
            // const formattedDate = data.updated_on.toLocaleString("en-US", options);
            // console.log(formattedDate)
            setPayDate(data.updated_on)}
        else{
            setPayDate("You have not made any payment")   
        }
        console.log(data)
        if (data.user_tier==="paid plan"){
        setTier("Premium")
        }
        else{
            setTier("FREE")
        }
        }
        if (pay_status){
            setContactsucess('close')
            setContactTemplate(false)
        }
        
     
        fetchData();
            
    },[])
    let options
    const getClientSecret =async()=>{
    if (username){
        
            const {data}=await axios.post(save_url+'dnaviz/payment_update/',{
                "username":username,
                })
            // console.log(data.client_secret)
            setStripeData(data.client_secret)
            // const appearance = {
            //     theme: 'flat',
            //     variables: { colorPrimaryText: '#262626' }
            //   };
            // const elements = stripe.elements({ stripedata, appearance });
            // const paymentElement = elements.create('payment', options);
            // paymentElement.mount('#payment-element');
            
            //   console.log(options)
            setPaymentTemplate(true)
}
    else{
    
        const {data}=await axios.post(save_url+'dnaviz/payment_update/',{
            "username":user_details.username,
            })
        console.log(data.client_secret)
        setStripeData(data.client_secret)
        
        //   console.log(options)
        setContactTemplate(false)
        setPaymentTemplate(true)
}
}
    // useEffect(()=>{
    //     console.log(stripedata)
    //     options = {
    //         // passing the client secret obtained from the server
    
    //         clientSecret: stripedata.client_secret
    //       };
    // },[stripedata])
    const Payment_Gateway=async()=>{
        setContactTemplate(false)
        setContactsucess("close")
        getClientSecret()
        
    }

    const handleOptionsClick = (event) => {
        setAnchorEl(event.currentTarget);
        let dummy_optionOpen = true
        // console.log("in open option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
    };
    const handleOptionsClose = () => {
        let dummy_optionOpen =  false
        // console.log("in close option",dummy_optionOpen[p_i][f_i])
        setOptionsopen(dummy_optionOpen)
        // setAnchorEl(null);
    };

    

    const contact_gateway=async()=>{
        setPaymentTemplate(false)
        setContactTemplate(true)
    }
    const handleSucessClose=()=>{
        setPaysucess('close')
    }

    const handleUsageChange=()=>{
        setOptionsopen(false)
        setPaymentTemplate(false)
        setContactTemplate(false)
        setPaysucess('close')
        setContactsucess('close')
        setBillDetails(false)
        setUsageReport(true)
        setUsageColor('rgba(236, 72, 153, 0.20)')
        setBillColor('var(--pallate-blue-gray-800, #1E293B)')
    }

    const handleContSucessClose=()=>{
        setContactsucess('close')
    }

    const handleBillChange=()=>{
        setOptionsopen(false)
        setPaymentTemplate(false)
        setContactTemplate(false)
        setPaysucess('close')
        setContactsucess('close')
        setBillDetails(true)
        setUsageReport(false)
        setUsageColor('var(--pallate-blue-gray-800, #1E293B)')
        setBillColor('rgba(34, 211, 238, 0.20)')
    }

    const handleViewBy=(event)=>{
        setViewBy(event)
        if (viewby=="Yearly"){
            setUseWidthMult(0.443)
        }
        else{
            setUseWidthMult(5.323)
        }
    }
    let error = {}
    function nameValidation(name) {
        var pattern = /^[a-zA-Z0-9-_ ]+$/;
           return pattern.test(name);
      }
      function emailValidation(email) {
        var pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(email);
      }
      function numberValidation(number) {
        var pattern = /^[0-9+]+$/;
        console.log(pattern.test(number))
        return pattern.test(number);
      }  
    const handlecontactussubmit= async (event)=>{
        event.preventDefault()
        contactname===''?error["name"] = "Field should not be empty":!nameValidation(contactname)?error["name"] = "Invalid name. Please use only letters, numbers, underscores, and hyphens.": delete error.name
        contactmail===''?error["email"] = "Field should not be empty":!emailValidation(contactmail)?error["email"] = "Please enter valid email address": delete error.email
        contactnumber!==''?!numberValidation(contactnumber)?error["number"] = "Please enter valid number": delete error.number:delete error.number
        contactcountry===''?error["country"] = "Field should not be empty":contactcountry=="Choose Your Country"?error["country"] = "Field should not be empty": delete error.country
        setRequired(error)
        if (Object.values(error).length===0) {
            const{data} = await axios.post('https://ot77rfq225.execute-api.us-east-2.amazonaws.com/default/dnaviz',{
            "name":contactname,
            "email":contactmail,
            "mobile":contactnumber,
            "subject":"Payment contact us enquiry from D&A VIZ",
            "country":contactcountry,
            "message":contactmessage
            })
            setContactsucess(true)
            setContactTemplate(false)

            setContactEmail('')
            setContactNumber('')
            setContactCountry('')
            setContactMessage('')
            setContactName('')
    }
}

    useEffect(()=>{
    async function data(){
    if (date!==""){
    // console.log(username)
    const {data} = await axios.post(save_url+'dnaviz/usage_report/',{
            "username":username,
            "date":date,
            "view_by":viewby}
            )
        setBarValue(data)
        // console.log(barvalue)
        if (viewby=="Yearly"){
            setUseWidthMult(0.443)
        }
        else{
            setUsageAmtWidth(5.323)
        }
        let cred = (data.overall_credits/100) * 5.323
        console.log(use_width_mult)
        let cred_used = (data.overall_credits/100) * 5.323
        let usage_amt = cred_used-17
        let credmt = (data.overall_credits/100).toFixed(2)
        setCredAmt("$"+credmt+"")
        setProgressWidth(cred_used +"" + "px")
        setActProgressWidth(1010 -cred_used +"" +"px")
        setActProgressMainWidth(995 -cred_used +"" +"px")
        setUsageAmtWidth(usage_amt+"" + "px")
        setData(data)
        
    }
    }data()
        
    },[date])

    const chartOptions = {
        aspectRatio: 2.0,
        scales: {
            x: {
                beginAtZero: true,
                // display:message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true,
                ticks: {
                    color: '#64748B',
                    display: true
                },
                grid: {
                    color: "#64748B",
                    display: false
                },
                barPercentage: 0.8, 
                categoryPercentage: 0.7,
            },
            y: {
                beginAtZero: true,
                // display:message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true,
                // title:{
                //  text:"Values -->",
                //  display:true,
                //  color:"white",
                //  fontSize:20
                // },
                ticks: {
                    color: '#64748B',
                    // display: message.response.viz === "pieChart" || message.response.viz === "doughnutChart" || message.response.viz === "polarareaChart" ? false : true
                },
                grid: {
                    color:"#64748B",
                    display: false
                }
            },
        },
        // onClick: (event, elements) => {
        //  if (elements.length > 0) {
        //      const datasetIndex = elements[0].datasetIndex;
        //      const dataIndex = elements[0].index;
        //      const dataValue = event.chart.data.datasets[datasetIndex].data[dataIndex];
        //  }
        // },/
        responsive: true,
        maintainAspectRatio: true,
        // plugins: {
        //  legend: {
        //      onClick: (e, legendItem, legend) => {
        //          const chart = legend.chart;
        //          const datasetIndex = chart.data.labels.indexOf(legendItem.text)
        //          chart.toggleDataVisibility(datasetIndex)
        //          chart.update();
        //      },
        //      display: true,
        //      position: 'bottom',
        //      labels: {
        //          color: '#fff',
        //          usePointStyle: true,
        //          pointStyle: 'circle',
        //          boxWidth: 6,
        //          boxHeight: 6,
        //          boxRadius: 5,
        //          padding: 18, 
        //          generateLabels: (chart) => {
        //              let visibility = []
        //              for (let i = 0; i < chart.data.labels.length; i++) {
        //                  if (chart.getDataVisibility(i) === true) {
        //                      visibility.push(false)
        //                  } else {
        //                      visibility.push(true)
        //                  }
        //              }
        //              const originalLabels = chart.data.labels;
                        
        //              const labels = [];

        //              originalLabels.forEach((label, index) => {
        //                  labels.push({
        //                      // text:!message.response.stacked?label.charAt(0).toUpperCase()+label.slice(1)+" "+"("+response.dataValues1[0][index]+", "+response.dataValues1[1][index]+")":label.charAt(0).toUpperCase()+label.slice(1)+" "+"("+response.dataValues1[0][index]+")",
        //                      text:label.charAt(0).toUpperCase() + label.slice(1),
        //                      fillStyle: colors[index],
        //                      hidden: visibility[index],
        //                      fontColor: "white",
        //                      categoryPercentage: 1,
        //                      barPercentage: 1,
                                
        //                  });
        //              });
        //              if(labels.length>=5){
        //                  for(let i=0;i<labels.length;i++){
        //              colors = [...colors,...["#00C2FF", "#9746FF", "#83FF21", "#FDE119", "#2DEEA8", "#FF6666"]];                         
                            
        //          }
        //                }
        //              return labels;
        //          },
        //      },
        //  },
        //  title: {
        //      display: true,
        //      text: 'Visualization',
        //      color: 'white',
        //  },
        // },
    };
    const chart_data = {
        labels: Array.isArray(barvalue.credits)
          ? barvalue.credits.map(entry => Object.keys(entry)[0])
          : [], // Extract the date labels
        datasets: [
          {
            label:viewby=="Monthly"?barvalue.month:barvalue.year,
            data: Array.isArray(barvalue.credits)
              ? barvalue.credits.map(entry => Object.values(entry)[0])
              : [], // Extract the credits values
            backgroundColor: 'var(--pallate-sky-500, #0EA5E9)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
            color:"#64748B"
          },
        ],
      };
      
      const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
          handlecontactussubmit(e); 
        }
      };

      const handleClosePaymentTemplate = () =>{
        setPaymentTemplate(false)
      }
    
      const handleCloseContactTemplate = () =>{
        setContactTemplate(false)
      }

      function redirectURL(){
        setOptionsopen(false)
        const url = `https://dnaviz.aipeople.arivu-iq.com/dashboard/`;
        //const url = `http://localhost:3000/dashboard/`;
        window.location.href = url;
        //window.open(`https://stageaiqpayment.nlugpt.io/payment/?username=${username}`);
        //window.open(`http://localhost:3001/payment/?username=${username}`, "_blank");
    }

    const handleCountryChange = value => {
        setValue(value)
        setContactCountry(value.label)
      }

      const customStyles = {
        container: (provided) => ({
          ...provided,
          width: '100%', 
          border:"none",
          outline:"none"
          // Adjust the width as needed
        }),
        // Add more styles as needed
      };
      



    return(
        <Box className="topbox">
            <Box sx={{margin:"0px 32px 0px 32px"}}>
            <Box className="backnname">
            <Box className="button-container1">
                <button className="back-button1" onClick={() => { redirectURL() }}> <img src={arrowLeft} className="arrow1" alt="arrow-icon" /></button>
            </Box>
            <Box  sx={{padding:"0"}} >
                                                                            <Button
                                                                           
                                                                                sx={{flexShrink:"0",background:"#64748B",color:"#FFF",fontSize:"20px",fontWeight:"800",width:"40px",height:"40px",borderRadius:"50px",marginTop:"35px",cursor:"pointer",border:"2px solid rgb(151, 70, 255)",marginTop:"32px",minWidth:"40px"}}
                                                                                id="file-option-button"
                                                                                aria-controls={Optionsopen ? 'file-option-menu' : undefined}
                                                                                aria-haspopup="true"
                                                                                aria-expanded={Optionsopen}
                                                                                onClick={(e)=>{e.stopPropagation();handleOptionsClick(e)}}
                                                                                
                                                                            >
                                                                                {username.toUpperCase()[0]}
                                                                            </Button>
                                                                            <Menu
                                                                                id="file-option-menu"
                                                                                anchorEl={anchorEl}
                                                                                open={Optionsopen}
                                                                                // open={true}
                                                                                onClose={()=>{handleOptionsClose()}}
                                                                                sx={{".MuiMenu-paper":{
                                                                                    backgroundColor:"var(--pallate-blue-gray-700, #334155)"
                                                                                }}}
                                                                                MenuListProps={{
                                                                                    'aria-labelledby': 'file-option-button',
                                                                                    'style':{
                                                                                        color:"var(--light-text-inverse-text, #FFF)",
                                                                                        borderRadius:"4px",
                                                                                        width:"120px",padding:"0"
                                                                                    }
                                                                                }}
                                                                            >
                                                                                {mob && <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",padding:"8px"}} onClick={()=> handleUsageChange()}>Usage Report</MenuItem>}
                                                                                {mob && <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",borderTop:"1px solid grey",padding:"8px"}} onClick={()=> handleBillChange()}>Billing Details</MenuItem>}
                                                                                <MenuItem sx={{padding:"3px",paddingLeft:"15px",display:"flex",alignItems:"center",borderTop:"1px solid grey",padding:"8px"}} onClick={()=> redirectURL()}>Back to Tool</MenuItem>
                                                                            </Menu>
                                                                            </Box>
                                                                            
                                                                        </Box>
                                                                        <Box className="top-line"></Box></Box>
        <Box className="paycontainer">
        
            {!mob && 
            <Box>
            <Box className="left-pane">
            <Box className="setting">
                <img src={SetIcon} style={{width:"35px", height:"35px"}}/>
                <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Setting</Typography>
            </Box>
            <Box className="set-details">
                <Box sx={{display:"flex", padding:"8px 12px", alignItems:"flex-start", gap:"12px", alignSelf:"stretch", background:usageColor,cursor:"pointer"}} onClick={()=>handleUsageChange()}>
                    <img src={UseIcon} style={{width:"24px", height:"24px"}}/>
                    <Typography sx={{color:"var(--pallate-blue-gray-50, #F8FAFC)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>Usage report</Typography>
                </Box>
                <Box sx={{display:"flex", padding:"8px 12px", alignItems:"flex-start", gap:"12px", alignSelf:"stretch", background:billColor,cursor:"pointer"}} onClick={()=>handleBillChange()}>
                    <img src={InvIcon} style={{width:"24px", height:"24px"}}/>
                    <Typography sx={{color:"var(--pallate-blue-gray-50, #F8FAFC)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"600", lineHeight:"24px"}}>Billing and Payment</Typography>
                </Box>

            </Box>
            
            </Box>
            
            </Box>}<Box className="left-line"></Box>
            <Box className="pay-right">
  {billDetails && !usageReport &&
            <Box className="pay-main">
                <Box className="pay-color">
                    <Box className="pay-infomain">
                        <Box className="pay-infohead">
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Payment Information</Typography>
                        <Box className="pay-line"></Box>
                        </Box>
                        <Box className="pay-plan-details">
                            <Box className="plan-head">
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>Current Plan:</Typography>
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>Credit Balance:</Typography>
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>Last Payment made:</Typography>
                            </Box>
                            <Box className="plan-head-1">
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>{tier}</Typography>
                            <Box className="credits-left">
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>{credits}</Typography>
                            <img src={HelpIcon} style={{width:"20px", height:"20px"}} title="Subscription Credits: Track your balance for uninterrupted access to premium features. Top up when needed for a seamless experience."/>
                            </Box>
                            <Typography sx={{color:"var(--pallate-blue-gray-100, #F1F5F9)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>{paydate}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className="price-main">
                <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Pricing Plan</Typography>
                <Box className="price-details">
                    <Box className="free-price">
                            <Box sx={{alignSelf:"stretch"}}>
                        <Box className="freemium">
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"500", lineHeight:"20px"}}>Free Trial</Typography>
                        </Box>
                        <Box className="free-details">
                            <Box className="free-header">
                                <Box className="freewithplan">
                                <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>$0</Typography>
                                {tier==="FREE" &&
                                <Box className="currentplan">
                                <Typography sx={{color:"var(--pallate-base-black, #000)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>Current Plan</Typography>
                                </Box>}
                                </Box>
                                <Typography sx={{color:"#FFF",width:"100%", maxWidth: "244px", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Dive into data effortlessly with our Freemium offering, delivering essential AI-powered visualizations and seamless exploration at no cost.</Typography>
                            <Box className="free-body">
                                {/* <Box className="free-credits">
                                    <img src={CredIcon} style={{maxWidth:"20px",width:"100%", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>100 Free Credits</Typography>
                                </Box> */}
                                <Box className="free-credits">
                                    <img src={PieIcon} style={{maxWidth:"20px",width:"100%", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Create Basic Charts</Typography>
                                </Box>
                                <Box className="free-credits">
                                    <img src={ChartIcon} style={{maxWidth:"20px",width:"100%", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Limited Visualization</Typography>
                                </Box>
                                <Box className="free-credits">
                                    <img src={QueIcon} style={{maxWidth:"20px",width:"100%", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Comprehensive Questions</Typography>
                                </Box>
                            </Box>
                            </Box>
                            <button className="subscribe-button">
                                <Typography sx={{color:"var(--pallate-blue-gray-700, #334155)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Subscribed</Typography>
                            </button>
                        </Box>
                        </Box>
                        </Box>
                    
                    <Box className="pre-price">
                    <Box sx={{alignSelf:"stretch"}}>
                        <Box className="premium">
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"500", lineHeight:"20px"}}>Premium</Typography>
                        </Box>
                        <Box className="pre-details">
                            <Box className="pre-header">
                                <Box className="prewithplan">
                                <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>$5</Typography>
                                {tier=="Premium" &&
                                <Box className="currentplan">
                                <Typography sx={{color:"var(--pallate-base-black, #000)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"400", lineHeight:"16px"}}>Current Plan</Typography>
                                </Box>}
                                </Box>
                                <Typography sx={{color:"#FFF", maxWidth:"244px",width:"100%", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Elevate your visualizations with personalized themes and branding options, exclusively available in our Premium plan.</Typography>
                            </Box>
                            <Box className="pre-body">
                                {/* <Box className="pre-credits">
                                    <img src={PCredIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>500 Credits</Typography>
                                </Box> */}
                                <Box className="pre-credits">
                                    <img src={PPieIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Basic Features +</Typography>
                                </Box>
                                {/* <Box className="pre-credits">
                                    <img src={PChartIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Allowed to Ask 20 Questions</Typography>
                                </Box> */}
                                <Box className="pre-credits">
                                    <img src={PTableIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Comprehensive Questions</Typography>
                                </Box>
                                {/* <Box className="pre-credits">
                                    <img src={PQueIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>For Any number of characters $5/-</Typography>
                                </Box> */}
                                <Box className="pre-credits">
                                    <img src={PExportIcon} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Export Capability</Typography>
                                </Box>
                                <Box className="pre-credits">
                                    <img src={shield} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Fully Secured Platform</Typography>
                                </Box>
                                <Box className="pre-credits">
                                    <img src={support} style={{width:"20px", height:"20px"}} />
                                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"500", lineHeight:"16px"}}>Support Included</Typography>
                                </Box>
                            </Box>
                            <button className="subscribenow-button" onClick={() =>Payment_Gateway()} >
                                <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Subscribe Now!</Typography>
                            </button>
                        </Box>
                        </Box>
                    </Box>
                    <Box className="custom-price">
                    <Box sx={{alignSelf:"stretch"}}>
                        <Box className="custom-plan">
                            <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"500", lineHeight:"20px"}}>Custom Plan</Typography>
                        </Box>
                        <Box className="custom-details">
                            <Box className="custom-header">
                                <Box className="cust-head-content">
                                <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>Let’s Talk</Typography>
                                <Box sx={{display:"flex", flexDirection:"column", gap:"15px"}}>
                                <Typography sx={{color:"#FFF", maxWidth:"244px",width:"100%", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Seamlessly integrate and visualize data from diverse sources, ensuring comprehensive analysis and actionable intelligence for your organization.</Typography>
                                <Typography sx={{color:"#FFF", maxWidth:"244px",width:"100%",fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Get in touch with our team to discuss custom pricing options that align with your unique requirements.</Typography>
                                <Typography sx={{color:"#FFF", maxWidth:"244px",width:"100%",fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Contact us today!</Typography>
                                </Box>
                                </Box>
                            </Box>
                            <button className="contactus-button" onClick={() =>contact_gateway()}>
                                <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Contact Us</Typography>
                            </button>
                        </Box>
                        </Box>
                    </Box>
                </Box>
                </Box>

            </Box>}

            {!billDetails && usageReport &&
            <Box className="usagemain">
                <Box className="usagecolor">
                    <Box className="usageinform">
                        <Box className="usagehead">
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Usage</Typography>
                        <Box className="usageline">
                        </Box>
                        </Box>
                        <Box className="stayinformed">
                        <Typography sx={{alignSelf: "stretch", color:"#FFF", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"700", lineHeight:"28px"}}>Stay informed!</Typography>
                        <Typography sx={{alignSelf: "stretch", color:"#FFF", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px",width:"100%",}}>Check your daily credit usage to make the most of our services. Monitor your balance and enjoy a seamless experience every day.</Typography>
                        </Box>
                    </Box>
                    <Box className="creditsviewmain">
                        <Box className="prodview">
                            <Box className="prodselect">
                            <Typography sx={{ maxWidth:"244px",width:"100%",alignSelf: "stretch", color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"400", lineHeight:"24px"}}>Select the Product to View usage</Typography>
                            <Box className="prodbody">
                                <select className="prodselbody" placeholder="select product">
                                <option className="seltext">
                                D&AVIZ
                                </option>
                                {/* <Box className="dropping">
                                    <img src={Drop} style={{width:"11.289px", height:"6,393px", flexShrink:"0"}}/>
                                </Box> */}
                                </select>
                            </Box>
                            </Box>
                            <Box className="viewselect">
                                <Box sx={{display:"flex", flexDirection:"column", gap:"8px"}}>
                                <Typography sx={{alignSelf: "stretch", color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"400", lineHeight:"24px"}}>View By</Typography>
                                <Box className="viewbody">
                                    <select className="viewselbody" placeholder="select view" onChange={(e)=> setViewBy(e.target.value)}>
                                    <option value="" disabled selected>Choose View by</option>
                                    <option>
                                    Monthly
                                    </option>
                                    <option>
                                    Yearly
                                    </option>
                                    {/* <Box className="dropping">
                                        <img src={Drop} style={{width:"11.289px", height:"6,393px", flexShrink:"0"}}/>
                                    </Box> */}
                                    </select>
                                </Box>
                                </Box>
                                <Box className="calenderbody">
                                    {/* <Calendar className="calinput"/> */}
                                {/* <label for="dateInput" className="calendaricon"/> */}
                                {viewby=="Monthly" && <input placeholder="Select Month" type="month" value={date} className="calinput" onChange={(e) => setDate(e.target.value)}/>}
                                {viewby=="Yearly" && <select id="monthInput" placeholder="Select Year" className="calinput-yearly" onChange={(e) => setDate(e.target.value)}>
                                <option value="" disabled selected>Select Year</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                    <option value="2025">2025</option>
                                    <option value="2026">2026</option>
                                    <option value="2027">2027</option>
                                    <option value="2028">2028</option>
                                    <option value="2029">2029</option>
                                    <option value="2030">2030</option>
                                    <option value="2031">2031</option>
                                    <option value="2032">2032</option>
  
                                    </select>}
                                
                                
                                </Box>
                            </Box>
                        </Box>
                        <Box className="creditsbar">
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"16px", fontStyle:"normal", fontWeight:"500", lineHeight:"24px"}}>Daily Usage of Credits</Typography>
                        <Box sx={{maxWidth:"1325px",width:"100%", height:"368px"}}>
                        <div style={{ display: "flex", flexDirection: "column",marginTop:"10px",width:"100%", maxWidth:"1301px", height:"328px"}}>
                            <Bar data={chart_data} options={chartOptions} sx={{width:"100%",maxWidth:"1000px" ,height:"500px"}}/>
                        </div>

                        </Box>
                        </Box>
                        <Box className="usagebar">
                        {viewby=="Monthly" && <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"700", lineHeight:"20px"}}>Usage this month (USD)</Typography>}
                        {viewby=="Yearly" && <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"700", lineHeight:"20px"}}>Usage this year (USD)</Typography>}
                        <Box className="progressbar">
                            <Box sx={{width:"100%",maxWidth:progressWidth, height:"20px", borderRadius: "0px 8px 8px 0px", background:"var(--pallate-pink-500, #EC4899)"}}>
                            </Box>
                        </Box>
                        <Box className="bottomvalues">
                        <Box sx={{marginTop:"-15px", maxWidth:progressWidth,width:"100%",display:"flex",justifyContent:"flex-end",alignItems:"flex-end",flexDirection:"column"}}>
                        <img src={Polygon} style={{widht:"14px", height:"10px", fill:"#A04F76",paddingRight:"15px"}}/>
                        <Box sx={{display:"flex", width:"35px", padding:"8px", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"8px", marginTop:"-5px", bottom:"0px", background: "#A04F76"}}>
                        <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"700", lineHeight:"16px"}}>{credamt}</Typography>   
                        </Box>
                        </Box>
                        <Box sx={{marginTop:"-15px",maxWidth:actprogressWidth+"30px",width:"100%",display:"flex",justifyContent:"flex-end",alignItems:"flex-end",flexDirection:"column"}}>
                        <img src={Polygon} style={{widht:"14px", height:"10px", fill:"#A04F76",paddingRight:"15px"}}/>
                        <Box sx={{display:"flex", width:"35px", padding:"8px", flexDirection:"column", justifyContent:"center", alignItems:"center", gap:"8px", marginTop:"-5px", bottom:"0px", background: "#A04F76"}}>
                        {viewby=="Monthly" && <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"700", lineHeight:"16px"}}>$198</Typography>}
                        {viewby=="Yearly" && <Typography sx={{color:"#FFF", fontFamily:"inter", fontSize:"12px", fontStyle:"normal", fontWeight:"700", lineHeight:"16px"}}>$2376</Typography>}
                        </Box>
                        </Box>
                        </Box>

                        </Box>
                    </Box>
                </Box>
            </Box>}
        
          {!mob && paymentTemplate && 
          <Box className="paytemp">
            {/* <Box className="pay-in-main">
                <Box className="paychoice">
                    <Box className="paychoicehead">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>Right  Choice!</Typography>
                    <Typography sx={{alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" ,gap:"10px"}}>You've just selected 
                        <span style={{ color: "var(--pallate-pink-500, #EC4899)", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px"}}>
                        {" "}Premium{" "}  
                        </span>
                        Plan tailored to meet all your needs. Enjoy the unmatched benefits!
                    </Typography>
                    </Box>
                    <Box className="pay-in-line"></Box>
                </Box>
                <Box className="card-details">
                <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>Enter you card Details</Typography>
                <Box className="checkout"> */}
                <Elements stripe={stripePromise} options={{clientSecret:stripedata, appearance:{theme: 'night'}}} style={{maxWidth:"320px",width:"100%"}}>
                <CheckoutForm user_name={username} saved_url={save_url} cancel={setPaymentTemplate} style={{maxWidth:"320px",width:"100%"}} />
                </Elements >
                {/* </Box>
                </Box>
            </Box> */}
          </Box>}

          { mob  && paymentTemplate && <Modal
                            open={paymentTemplate}
                            onClose={() => handleClosePaymentTemplate()}
                            closeAfterTransition
                        >
                            <Box className="paytemp">
            {/* <Box className="pay-in-main"> */}
                {/* <Box className="paychoice">
                    <Box className="paychoicehead">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>Right  Choice!</Typography>
                    <Typography sx={{alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" ,gap:"10px"}}>You've just selected 
                        <span style={{ color: "var(--pallate-pink-500, #EC4899)", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px"}}>
                        {" "}Premium{" "}  
                        </span>
                        Plan tailored to meet all your needs. Enjoy the unmatched benefits!
                    </Typography>
                    </Box>
                    <Box className="pay-in-line"></Box>
                </Box>
                <Box className="card-details">
                <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"800", lineHeight:"32px"}}>Enter you card Details</Typography>
                <Box className="checkout"> */}
                <Elements stripe={stripePromise} options={{clientSecret:stripedata, appearance:{theme: 'night'}}} style={{maxWidth:"320px",width:"100%"}}>
                <CheckoutForm uid={unique_id} user_name={username} saved_url={save_url} cancel={setPaymentTemplate} style={{maxWidth:"320px",width:"100%"}} />
                </Elements >
                {/* </Box>
                </Box> */}
            {/* </Box> */}

          </Box></Modal>}


          {pay_status && paysuccess!=="close" &&
          <Box className="successtemp">
                <img className="close-icon" src={Close} onClick={()=>handleSucessClose()}/>
            <Box className="successmain">
                <Box className="successmsg">
                    <img src={Semoji} style={{width:"135px", height:"135px"}}/>
                    <Box className="congmsg">
                    <Typography sx={{alignSelf:"stretch", textAlign:"center", color:"#FFF", fontFamily:"inter", fontSize:"30px", fontStyle:"normal", fontWeight:"700", lineHeight:"36px"}}>Congratulations!</Typography>
                    <Typography sx={{alignSelf:"stretch", textAlign:"center", color:"#FFF", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"600", lineHeight:"28px"}}>Your payment was successful. Thank you for choosing us. We appreciate your business and look forward to serving you!</Typography>
                    </Box>
                </Box>
                <Box className="successline"></Box>
                <Box className="earnedmsg">
                <Typography sx={{alignSelf:"stretch", textAlign:"center", color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"500", lineHeight:"32px"}}>You have earned</Typography>
                <Box className="earncredit">
                <Typography sx={{alignSelf:"stretch", textAlign:"center", background: "linear-gradient(155deg, #00C2FF 0.53%, #9746FF 88.22%)",  backgroundClip: "text", WebkitBackgroundClip:"text", WebkitTextFillColor:"transparent", fontFamily:"inter", fontSize:"48px", fontStyle:"normal", fontWeight:"800", lineHeight:"57px"}}>500</Typography>
                <Typography sx={{alignSelf:"stretch", textAlign:"center", color:"#FFF", fontFamily:"inter", fontSize:"18px", fontStyle:"normal", fontWeight:"600", lineHeight:"28px"}}>Credit points</Typography>
                </Box>
                </Box>
            </Box>
          </Box>}

          {!mob && contactTemplate && 
          <Box className="contactmain">
            <Box className="contactdetails">
                <Box className="contacthead">
                    <Box className="contacttop">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Contact Us</Typography>
                    <Typography sx={{alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" ,gap:"10px"}}>Interested in a custom plan? Please fill out the form below, and we'll reach out to discuss a tailored solution that fits your needs perfectly.</Typography>
                    </Box>
                    <Box className="contactline"></Box>
                    <form onSubmit={(e) => handlecontactussubmit(e)} style={{width:"100%"}}>
                    <Box className="contactbody">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Enter your details</Typography>
                    <Box className="detailsbody">
                        <Box className="detailsbodymain">
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Name <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                <input  placeholder="Enter your name" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactName(e.target.value)}/>
                            </Box>
                            {required.name&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.name}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Country <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                {/* <select type="country" required placeholder="Choose Your Country" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onKeyDown={(e)=>handleKeyPress(e)} onChange={(e)=>setContactCountry(e.target.value)}>
                                <option value="" disabled selected>Choose Your Country</option>
                                <option value="India">India</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="UK">UK</option>
                                <option value="US">US</option>
                                </select> */}
                               
                               {/* <Select
                               sx={{background:"transparent",outline:"none",border:"none",padding:"0px",color:"black"}}
                               fullWidth
                               value={contactcountry}
                               onChange={(e)=>setContactCountry(e.target.value)}
                                  >
                                      
                                {country_options.map((country)=>(

                                        <MenuItem value={country.label}> {country.label}</MenuItem>               
                                        ))} 
                                        
                               </Select> */}
                                 <select value= {contactcountry} style={{color:"black", display:"flex", justifyContent:"space-between",color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%",background:"transparent",position:"relative"}} onChange={(e)=>setContactCountry(e.target.value)}>
                                 <option > Choose Your Country</option>
                                   {country_options.map((country)=>(
                                    <option > {country.label}</option>
                                   ))} 
                                </select>
                            </Box>
                            {required.country&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.country}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Email Address <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                <input  placeholder="Enter your Email ID" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactEmail(e.target.value)}/>
                            </Box>
                            {required.email&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.email}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Contact Number 
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                {/* {contactcountry==="India" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +91-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+91"+e.target.value+"")}/></Box>}
                                    {contactcountry==="Malaysia" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +60-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+60"+e.target.value+"")}/></Box>}
                                    {contactcountry==="UK" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +44-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+44"+e.target.value+"")}/></Box>}
                                    {contactcountry==="US" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +1-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+1"+e.target.value+"")}/></Box>} */}
                            <Box sx={{display:"flex", flexDirection:"row",width:"100%"}}>
                                    <input placeholder="Enter Your Contact Number with Code" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber(e.target.value)}/></Box>

                            </Box>
                            {required.number&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.number}</Typography>
                                            </Box>}
                            </Box>
                            
                            <Box className="message">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                            Enter your message (Optional) </Typography>
                            <Box sx={{alignSelf:"stretch", borderRadius:"4px"}}>
                            <textarea placeholder="Enter your Message" style={{height:"80px", padding:"12px",color: "var(--light-text-secondary-text, #64748B)",  borderRadius:"4px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%",maxWidth:"-webkit-Fill-Available" }} onChange={(e)=>setContactMessage(e.target.value)}/></Box>
                        {/* <Box className="enter-message">
                        </Box> */}
                        </Box>
                        </Box>
                        
                    </Box>
                    <Box className="contactfooter">
                    <button type="submit" style={{border: "none",display:"flex", width:"125px", height:"35px", padding:"8px 12px", justifyContent:"center", alignItems:"center", gap:"8px", borderRadius: "4px", background: "var(--pallate-indigo-500, #6366F1)", cursor:"pointer"}}>
                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Submit</Typography>
                    </button>
                    <button style={{display:"flex", width:"125px", height:"35px", padding:"8px 12px", justifyContent:"center", alignItems:"center", gap:"8px", borderRadius: "4px", border: "1px solid var(--pallate-indigo-500, #6366F1)", background:"none", cursor:"pointer"}} onClick={() => setContactTemplate(false)}>
                    <Typography sx={{color:"var(--pallate-indigo-500, #6366F1)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"500", lineHeight:"20px"}}>Cancel</Typography>
                    </button>
                    </Box>
                    </Box>
                    </form>
                    
                </Box>
            </Box>
          </Box>}
        
          {mob && contactTemplate && <Modal
                            open={contactTemplate}
                            onClose={() => handleCloseContactTemplate()}
                            closeAfterTransition
                        >
          <Box className="contactmain">
            <Box className="contactdetails">
                <Box className="contacthead">
                    <Box className="contacttop">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Contact Us</Typography>
                    <Typography sx={{alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "20px", fontStyle: "normal", fontWeight: "500", lineHeight: "28px" ,gap:"10px"}}>Interested in a custom plan? Please fill out the form below, and we'll reach out to discuss a tailored solution that fits your needs perfectly.</Typography>
                    </Box>
                    <Box className="contactline"></Box>
                    <form style={{width:"100%"}} onSubmit={(e) => handlecontactussubmit(e)} >
                    <Box className="contactbody">
                    <Typography sx={{alignSelf:"stretch",color:"#FFF", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Enter your details</Typography>
                    <Box className="detailsbody">
                        <Box className="detailsbodymain">
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Name <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                <input  placeholder="Enter your name" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactName(e.target.value)}/>
                            </Box>
                            {required.name&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.name}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Country <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                {/* <select type="country" required placeholder="Choose Your Country" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onKeyDown={(e)=>handleKeyPress(e)} onChange={(e)=>setContactCountry(e.target.value)}>
                                <option value="" disabled selected>Choose Your Country</option>
                                <option value="India">India</option>
                                <option value="Malaysia">Malaysia</option>
                                <option value="UK">UK</option>
                                <option value="US">US</option>
                                </select> */}
                                 <select value= {contactcountry} style={{color:"black", display:"flex", justifyContent:"space-between",color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%",background:"transparent",position:"relative"}} onChange={(e)=>setContactCountry(e.target.value)}>
                                 <option > Choose Your Country</option>
                                   {country_options.map((country)=>(
                                    <option > {country.label}</option>
                                   ))} 
                                </select>
                            </Box>
                            {required.country&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.country}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Email Address <Typography component="span" sx={{color:"var(--light-text-danger-text, #EF4444)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px"}}>*</Typography>
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                <input placeholder="Enter your Email ID" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactEmail(e.target.value)}/>
                            </Box>
                            {required.email&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.email}</Typography>
                                            </Box>}
                            </Box>
                            <Box className="namedet">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                                Contact Number 
                            </Typography>
                            <Box sx={{ display: "flex", padding: "12px", alignItems: "center", gap: "8px", alignSelf: "stretch", borderRadius: "4px", border: "1px solid var(--light-forms-border, #CBD5E1)", background: "var(--light-forms-background, #FFF)" }}>
                                {/* {contactcountry==="India" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +91-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+91"+e.target.value+"")}/></Box>}
                                    {contactcountry==="Malaysia" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +60-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+60"+e.target.value+"")}/></Box>}
                                    {contactcountry==="UK" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +44-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+44"+e.target.value+"")}/></Box>}
                                    {contactcountry==="US" && <Box sx={{display:"flex", flexDirection:"row"}}><Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--light-text-secondary-text, #64748B)", textAlign: "left" }}>
                        +1-</Typography>
                                    <input type="number" placeholder="Enter Your Contact Number" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+1"+e.target.value+"")}/></Box>} */}
                            <Box sx={{display:"flex", flexDirection:"row"}}>
                                    <input placeholder="Enter Your Contact Number with Code" style={{ color: "var(--light-text-secondary-text, #64748B)", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%" }} onChange={(e)=>setContactNumber("+91"+e.target.value+"")}/></Box>

                            </Box>
                            {required.number&&<Box sx={{marginBottom:"-15px"}}>
                                            <Typography sx={{ color: "#EF4444" }}>{required.number}</Typography>
                                            </Box>}
                            </Box>
                            
                            <Box className="message">
                            <Typography sx={{ fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", color: "var(--pallate-blue-gray-50, #FFF)", textAlign: "left" }}>
                            Enter your message (Optional) </Typography>
                            <Box sx={{alignSelf:"stretch", borderRadius:"4px"}}>
                            <textarea placeholder="Enter your Message" style={{height:"80px", padding:"12px",color: "var(--light-text-secondary-text, #64748B)",  borderRadius:"4px", fontFamily: "inter", fontSize: "16px", fontStyle: "normal", fontWeight: "400", lineHeight: "24px", border: "none", outline: "none", width: "100%",maxWidth:"-webkit-Fill-Available" }} onChange={(e)=>setContactMessage(e.target.value)}/></Box>
                        {/* <Box className="enter-message">
                        </Box> */}
                        </Box>
                        </Box>
                        
                    </Box>
                    <Box className="contactfooter">
                    <button type="submit" style={{border: "none",display:"flex", width:"125px", height:"35px", padding:"8px 12px", justifyContent:"center", alignItems:"center", gap:"8px", borderRadius: "4px", background: "var(--pallate-indigo-500, #6366F1)", cursor:"pointer"}}>
                    <Typography sx={{color:"var(--pallate-base-white, #FFF)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"400", lineHeight:"20px"}}>Submit</Typography>
                    </button>
                    <button style={{display:"flex", width:"125px", height:"35px", padding:"8px 12px", justifyContent:"center", alignItems:"center", gap:"8px", borderRadius: "4px", border: "1px solid var(--pallate-indigo-500, #6366F1)", background:"none", cursor:"pointer"}} onClick={() => setContactTemplate(false)}>
                    <Typography sx={{color:"var(--pallate-indigo-500, #6366F1)", fontFamily:"inter", fontSize:"14px", fontStyle:"normal", fontWeight:"500", lineHeight:"20px"}}>Cancel</Typography>
                    </button>
                    </Box>
                    </Box>
                    </form>
                    
                </Box>
            </Box>
          </Box></Modal>}
        

          {!mob && contactsuccess!=="close" && contactTemplate===false &&
          <Box className="contsuccesstemp">
          <img className="contact-close-icon" src={Close} onClick={()=>handleContSucessClose()}/>
          <Box className="contsuccessmain">
              <Box className="contactsucessbody">
                <Box className="thankmsg">
                <Typography sx={{textAlign:"center",alignSelf:"stretch",color:"var(--pallate-pink-500, #EC4899)", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Thank you for reaching out!</Typography>
                <Typography sx={{textAlign:"center",alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "700", lineHeight: "32px" ,gap:"10px"}}>Our team will be in touch with you shortly to assist you further.</Typography>
                </Box>
              </Box>
          </Box>
          </Box>
          }

        {mob && contactsuccess!=="close" && contactTemplate===false &&
          <Modal
          open={contactsuccess}
          onClose={() => handleContSucessClose()}
          closeAfterTransition
            >
          <Box className="contsuccesstemp">
          <img className="contact-close-icon" src={Close} onClick={()=>handleContSucessClose()}/>
          <Box className="contsuccessmain">
              <Box className="contactsucessbody">
                <Box className="thankmsg">
                <Typography sx={{textAlign:"center",alignSelf:"stretch",color:"var(--pallate-pink-500, #EC4899)", fontFamily:"inter", fontSize:"24px", fontStyle:"normal", fontWeight:"700", lineHeight:"32px"}}>Thank you for reaching out!</Typography>
                <Typography sx={{textAlign:"center",alignSelf:"stretch", color: "#FFF", fontFamily: "inter", fontSize: "24px", fontStyle: "normal", fontWeight: "700", lineHeight: "32px" ,gap:"10px"}}>Our team will be in touch with you shortly to assist you further.</Typography>
                </Box>
              </Box>
          </Box>
          </Box>
          </Modal>}
</Box>
        </Box>
        </Box>

    )
}